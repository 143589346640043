import React from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

interface IProps<Name extends string> {
  name: Name;
  title: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
}

export const FormInput = <T extends string>({
  name,
  title,
  type,
  placeholder,
  required,
  className,
}: IProps<T>) => {
  const [field, meta] = useField(name);

  return (
    <Form.Group>
      <Form.Control
        isInvalid={meta.touched && !!meta.error}
        type={type}
        className={className}
        placeholder={placeholder}
        required={required}
        title={title}
        {...field}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};
