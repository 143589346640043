import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const ButtonSpinner = () => (
  <>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    <span className="sr-only">Загрузка...</span>
  </>
);
