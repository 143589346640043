import axios from 'axios';
import { environment } from 'containers/System/Environment';

export const createAxiosInstance = () =>
  axios.create({
    baseURL: environment.apiPath,
  });

export const API_PATHS = {
  landing: {
    leads: 'landing/leads',
    leadsFriend: 'landing/leads/friend',
    callMe: 'landing/callme',
    reviews: 'landing/reviews',
  },
};
