export const apiMessages = {
  lead: {
    success:
      'Мы успешно приняли вашу заявку и свяжемся с вами в ближайшее время для уточнения подробностей',
  },
  friendLead: {
    success:
      'Мы успешно приняли вашу заявку и свяжемся с вами в ближайшее время для уточнения подробностей',
  },
  review: {
    success:
      'Благодарим вас за оставленный отзыв, нам очень важно ваше мнение!',
  },
  callMe: {
    success:
      'Благодарим вас за оставленную заявку, мы с вами свяжемся с вами в ближайшее время!',
  },
};
